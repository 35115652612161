/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { kebabCase, uniq} from 'lodash'
import { RiArrowRightSLine } from "react-icons/ri"
import sIcons from "../components/sicons"

import Layout from "../components/layout"
import BlogListHome from "../components/blog-list-home"
import Seo from "../components/seo"

export const pageQuery = graphql`
  query HomeQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        tagline
        featuredImage {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 585, height: 439)
          }
        }
        cta {
          ctaText
          ctaLink
        }
      }
    }
    posts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { template: { eq: "blog-post" } } }
      limit: 6
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            description
            tags
            author
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 345, height: 260)
              }
            }
          }
        }
      }
    }
  }
`

const HomePage = ({ data }) => {
  const { markdownRemark, posts } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const { edges } = posts
  let tags = []
  let authors = []
  edges.map ((n) => {
    authors.push(n.node.frontmatter.author)
    n.node.frontmatter.tags.map((t) => {
      tags.push(t)
      return null
    })
    return null
  })

  tags = uniq(tags)
  authors = uniq(authors)
  
  // const { title, tags } = node.frontmatter
  const Image = frontmatter.featuredImage
    ? frontmatter.featuredImage.childImageSharp.gatsbyImageData
    : ""
 
  return (
    <Layout>
      <Seo />
      <div className="home-banner grids col-1 sm-2">

        <div>
          <h1 className="title">{frontmatter.title}</h1>
          <p
            className="tagline"
            sx={{
              color: "muted",
            }}
          >
            {frontmatter.tagline}
          </p>
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: html }}
          />
          <Link
            to={frontmatter.cta.ctaLink}
            className="button"
            sx={{
              variant: "variants.button",
            }}
          >
            {frontmatter.cta.ctaText}
            <span className="icon -right">
              <RiArrowRightSLine />
            </span>
          </Link>
          <div
            className="social-icons"
            sx={{
              variant: "variants.socialIcons",
            }}
          >
            {sIcons}
          </div>
        </div>

        <div>
          {Image ? (
            <GatsbyImage
              image={Image}
              alt={frontmatter.title + " - Featured image"}
              className="featured-image"
            />
          ) : (
            ""
          )}
        </div>
      </div>

      {tags && tags.length ? (
        <div style={{ marginTop: `4rem` }}>
          <h2>Topics 📘 </h2>
          <ul className="tags tagsindexcard">
            {tags.slice(0, 6).map((tag) => (
              <li key={tag + `tag`} >
                <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
      
      {authors && authors.length ? (
        <div style={{ marginTop: `4rem` }}>
          <h2>Authors 🧑🏻‍💻👩🏻‍💻 </h2>
          <ul className="tags authorindexcard">
            {authors.slice(0, 8).map((author) => (
              <li key={author + `author`} >
                <Link to={`/author/${kebabCase(author)}/`}>{author}</Link>
              </li>
            ))}
          </ul>
        </div>
      ) : null}

      <BlogListHome data={posts} />
    </Layout>
  )
}

export default HomePage
